
<div>
  <div id="finder">
    <div class="container">
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="bordered bg-lightblue p-4">
            <div class="input-group">
              <input id="finder-field" v-model="selected.search" @keydown="search_delay(0.4, 'new')"
                placeholder="Suchbegriff" class="form-control bg-white" />
              <div class="input-group-append">
                <button class="btn color-white bg-red" @click="run_search('new')">
                  <img src="/img/icons/finder/Finder-Icon-Lupe-weiss.png" class="icon" />
                </button>
              </div>
            </div>
            <div class="in pb-3 mt-2">
              <div class="row">
                <div class="col-md-4">
                  <button class="textlink-nodesign color-dark pl-2" @click="reset_selected">
                    Eingaben zur&uuml;cksetzen
                  </button>
                </div>
                <div class="col-md-8 text-right" v-if="mandant == false">
                  <button class="btn btn-primary filter" @click="toggle_filter('partner')"
                    v-bind:class="{ active: selected.partner }" v-if="partner == true">
                    nur Kurse des Partnerprogramms anzeigen
                  </button>
                  <button class="btn btn-primary filter" @click="search_type('inhouse')"
                    v-bind:class="{ active: selected.type == 'inhouse' }">
                    Inhouse/Beratung
                  </button>
                  <button class="btn btn-primary filter" @click="search_type('elearning')"
                    v-bind:class="{ active: selected.type == 'elearning' }">
                    E-Learning
                  </button>
                  <button class="btn btn-primary filter" @click="toggle_filter('weiteres')"
                    v-bind:class="{ active: more_filter }">
                    weitere Filter
                  </button>
                  <span style="width: 145px; display: inline-block"> </span>
                </div>
              </div>
              <div class="row mt-2" v-if="more_filter && mandant == false" style="margin-right: 135px">
                <div class="col-md-4"></div>
                <div class="col-md-2">
                  <select class="custom-select" v-model="selected.referent" @change="search_delay(0.4, 'new')">
                    <option value="">Referent</option>
                    <option v-for="(referent, referent_index) in filter.referent" :key="referent_index"
                      :value="referent.code">
                      {{ referent.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <select class="custom-select" v-model="selected.theme" @change="search_delay(0.4, 'new')">
                    <option value="">Kategorie</option>
                    <option v-for="(kategorie, kategorie_index) in filter.kategorie" :key="kategorie_index"
                      :value="kategorie.code">
                      {{ kategorie.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <select class="custom-select" v-model="selected.datum" @change="search_delay(0.4, 'new')">
                    <option value="">Datum</option>
                    <option v-for="(datum, datum_index) in filter.datum" :key="datum_index" :value="datum.value">
                      {{ datum.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <select class="custom-select" v-model="selected.ort" @change="search_delay(0.4, 'new')">
                    <option value="">Ort</option>
                    <option v-for="(ort, ort_index) in filter.ort" :key="ort_index" :value="ort.value">
                      {{ ort.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2" v-if="more_filter && mandant == false" style="margin-right: 135px">
                <div class="col-md-12 text-right">
                  <button class="textlink-nodesign color-dark pl-2" @click="reset_selected">
                    Filter zur&uuml;cksetzen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="seminar-list">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-3">
          <div class="list-seminare">
            <!-- Header Row -->
            <div class="row title_row" v-if="data_list.length > 0">
              <div class="col-md-6 title">
                Angebot
                <div class="sort_icons">
                  <a href="#seminar-list" @click="sort_by('name', 'asc')" :class="{ active: is_sort('name', 'asc') }"
                    class="sort_icon sort_icon_up">
                    Aufsteigend sortieren
                  </a>
                  <a href="#seminar-list" @click="sort_by('name', 'desc')"
                    :class="{ active: is_sort('name', 'desc') }" class="sort_icon sort_icon_down">
                    Absteigend sortieren
                  </a>
                </div>
              </div>
              <div class="col-md-2 location">
                Ort
                <div class="sort_icons">
                  <a href="#seminar-list" @click="sort_by('location', 'asc')"
                    :class="{ active: is_sort('location', 'asc') }" class="sort_icon sort_icon_up">
                    Aufsteigend sortieren
                  </a>
                  <a href="#seminar-list" @click="sort_by('location', 'desc')"
                    :class="{ active: is_sort('location', 'desc') }" class="sort_icon sort_icon_down">
                    Absteigend sortieren
                  </a>
                </div>
              </div>
              <div class="col-md-1 date">
                Datum
                <div class="sort_icons">
                  <a href="#seminar-list" @click="sort_by('sortdate', 'asc')"
                    :class="{ active: is_sort('sortdate', 'asc') }" class="sort_icon sort_icon_up">
                    Aufsteigend sortieren
                  </a>
                  <a href="#seminar-list" @click="sort_by('sortdate', 'desc')"
                    :class="{ active: is_sort('sortdate', 'desc') }" class="sort_icon sort_icon_down">
                    Absteigend sortieren
                  </a>
                </div>
              </div>
              <div class="col-md-2 date">Referent/in</div>
              <div class="col-md-1 booking">
                Preis
                <span v-if="login && mandant == false && selected.partner" class="af_red"> Provision </span>
              </div>
            </div>
            <!-- Seminar List -->
            <div class="row" v-for="(seminar, seminar_index) in data_list" :key="seminar_index">
              <div class="col-xl-6 title border-right">
                <span class="seminar-status" :class="{
                  'status-good': seminar.status == 3,
                  'status-critical': seminar.status == 2,
                  'status-bad': seminar.status == 1,
                }" :title="seminar.name">
                </span>
                <a :href="seminar.link">{{ seminar.name }}</a>
                <br />
                <span>
                  <span v-for="(kategorie, kat_index) in seminar.kategorie" :key="kat_index">
                    <span v-if="((mandant == false) || (kategorie.includes('comTeam') == false))">
                      {{ kategorie }}
                    </span>
                    <span v-if="seminar.kategorie.length != kat_index + 1">, </span>
                  </span>
                </span>
                <br v-if="seminar.af_link != '' && seminar.af_link != null && mandant == false" />
                <span v-if="seminar.af_link != '' && seminar.af_link != null && mandant == false">
                  <a href="#!" class="af_red" @click="copy_url(seminar.af_link)">
                    Affiliate: {{ get_url(seminar.af_link) }}
                  </a>
                </span>
              </div>
              <div class="col-xl-2 location border-right">
                <a :href="seminar.link" class="row_icon_arrow">
                  <img src="/img/icons/comteam-campus-icon-pfeilunten.png" />
                </a>
                {{ seminar.ort }}
              </div>
              <div class="col-xl-1 date border-right">
                {{ seminar.datum }} <br />
                {{ seminar.diff }}
              </div>
              <div class="col-xl-2 referent border-right">
                {{ seminar.referent }}
              </div>
              <div class="col-xl-1 price">
                <span v-if="seminar.preis_e != '' && seminar.preis_e != 0">
                  <span class="price_table">
                    <strong>&euro; {{ seminar.preis_e }}</strong>
                  </span>
                </span>
                <span v-if="seminar.preis_e === 0">
                  <span class="price_table">
                    <strong> Kostenfrei </strong>
                  </span>
                </span>
                <span v-if="seminar.preis_e === '' && seminar.preis_i === ''">
                  <span class="price_table"> Auf Anfrage </span>
                </span>
                <br />
                <span v-if="mandant == false">
                  <span v-if="seminar.preis_i != '' && seminar.preis_i != 0">
                    * &euro; {{ seminar.preis_i }}
                  </span>
                  <span v-if="seminar.preis_i === 0"> Kostenfrei </span>
                  <br />
                  <span v-if="seminar.preis_p != '' && seminar.preis_p != 0" class="af_red">
                    &euro; {{ seminar.preis_p }}
                  </span>
                </span>
              </div>
            </div>
            <div class="row row mt-3 pb-3 pl-3 pr-3" v-if="data_list.length > 0">
              <!-- Legende -->
              <div class="col-md-7 pl-0">
                <div class="status-index mt-3">
                  <span class="slot">
                    <span class="seminar-status status-good"></span> Plätze frei
                  </span>
                  <span class="slot">
                    <span class="seminar-status status-critical"></span> Plätze limitiert
                  </span>
                  <span class="slot">
                    <span class="seminar-status status-bad"></span> leider ausgebucht
                  </span>
                  <span class="slot" v-if="mandant == false">
                    <span class="big_asterisk">*</span>Partnerpreis pro Person
                  </span>
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-md-5 text-left">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link prev bold page-link-text" href="#seminar-list" @click="page_prev"
                      v-if="data_page > 1">
                      Zurück
                    </a>
                  </li>
                  <li class="page-item page-item-round" v-for="(step, step_index) in step_page" :key="step_index"
                    :class="{ active: step == data_page }">
                    <a v-if="step != null" class="page-link" href="#seminar-list" @click="page_set(step)">
                      {{ step }}
                    </a>
                    <span v-if="step == null" class="page-link"> ... </span>
                  </li>
                  <li class="page-item next">
                    <a class="page-link page-link-text" href="#seminar-list" @click="page_next"
                      v-if="data_page < max_page">
                      Vor
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
